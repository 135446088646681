<template>
  <div>
    <!-- <div class="crts-container"> -->
    <div
      class="crts-container"
      v-if="loading == false && cartItems().length > 0"
    >
      <!-- <div class="cart-item-container"> -->
      <div
        class="cart-item-container"
        v-for="(item, a) in cartItems()"
        :key="a"
      >
        <div>
          <p class="cart-item-txt">item</p>
          <div class="input-ctr">
            <p class="cart-item-txt" style="margin-right: 10px">quantity:</p>

            <input
              v-model="item.quantity"
              :disabled="disabled"
              class="cart-item-txt"
              style="margin-right: 20px"
            />
            <div
              style="cursor: pointer"
              v-if="disabled"
              @click="setDisabledFalse(item.quantity)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 576 512"
              >
                <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path
                  d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"
                />
              </svg>
            </div>
            <div v-else class="input-ctr">
              <p
                @click="setDisabledTrue(a)"
                class="x"
                style="margin-right: 20px"
              >
                x
              </p>
              <i
                class="fa-thin fa-check fa-lg"
                style="cursor: pointer"
                @click="editQuantity(item.key, item.quantity)"
              ></i>
            </div>
          </div>
          <p class="cart-item-txt">Price Per Item: ${{ formatPrice(10.0) }}</p>
        </div>
        <div class="cart-right">
          <p class="cart-right-txt">${{ formatPrice(20) }}</p>
          <div @click="deleteItem(item)">
            <p class="x">x</p>
          </div>
        </div>
      </div>
      <div class="price-container">
        <div class="price-left">
          <p class="cart-item-txt">
            Subtotal: ${{ formatPrice(cartData().totals.total_items) }}
          </p>
          <p class="cart-item-txt">
            Sales Tax: ${{ formatPrice(cartData().totals.total_items_tax) }}
          </p>
        </div>
        <div class="price-right">
          <p class="price-right-txt">
            estimated total cost: ${{
              formatPrice(cartData().totals.total_price)
            }}
          </p>
        </div>
      </div>
    </div>
    <div class="loading-ctr" v-else-if="loading">
      <b-spinner variant="primary"></b-spinner>
      <b-spinner variant="primary"></b-spinner>
      <b-spinner variant="primary"></b-spinner>
    </div>
    <div class="empty-crt" v-else-if="!loading && cartItems().length == 0">
      <p>There are no items in your cart</p>
      <p>To checkout, please add something to your cart</p>
    </div>
    <div class="goto-next">
      <div
        class="btn-cancel c-pointer"
        v-if="cur_stage === 'appt_store'"
        @click="hideModal"
      >
        Cancel
      </div>
      <div class="btn-back c-pointer" v-else @click="back">Back</div>
      <div
        v-if="cartItems().length > 0"
        class="btn-next btn-book c-pointer active"
        @click="next"
      >
        Next
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Cart",
  created: function () {
    this.getCart();
  },

  methods: {
    async deleteItem(item) {
      this.loading = true;
      const instance = await this.createInstance();
      try {
        await instance.delete("/cart/items/" + item.key, {
          headers: {
            Nonce: this.nonce,
          },
        });
        this.getCart();
      } catch (err) {
        console.error(err);
      }
    },
    back() {
      let form = { type: "checkout", change: "reduce" };
      this.$store.commit("FormStepBackCheckout", form);
    },
    next() {
      var form = { type: "checkout", change: "increment" };
      this.$store.commit("FormStepBackCheckout", form);
      if (
        this.storeSelected &&
        this.cur_stage == "appt_service" &&
        this.$store.state.components.appointment.availability.length == 0
      ) {
        this.$store.dispatch(
          "getAvailability",
          this.storeSelected["location-id"]
        );
      }
    },
    cartItems: function () {
      return this.$store.state.cart.items;
    },
    cartData: function () {
      return this.$store.state.cart.data;
    },
    formatPrice: function (price) {
      return (Number(price) / 100).toFixed(2);
    },
    getCart: async function () {
      try {
        const instance = await this.createInstance();
        const data = await instance.get("/cart");
        this.nonce = data.headers?.nonce;
        this.$store.commit("setCartItems", data.data.items);
        this.$store.commit("setCartData", data.data);
      } catch (err) {
        console.error("get cart error", err);
      } finally {
        this.loading = false;
      }
    },
    setDisabledTrue: function (a) {
      this.cartItems()[a].quantity = this.savedValue;
      this.disabled = true;
    },
    setDisabledFalse: function (value) {
      this.savedValue = value;
      this.disabled = false;
    },
    async editQuantity(key, quantity) {
      const instance = await this.createInstance();
      await instance.put("/cart/items/" + key + "?quantity=" + quantity);
      this.disabled = true;
      await this.getCart();
    },
    createInstance: async function () {
      const apiURL = "https://tint-world.lndo.site/wp-json/wc/store/v1/";
      const consumerKey = "ck_3d78f854a67ed2c706e0393055a4655f05115cbe";
      const consumerSecret = "cs_19f97dcc97346aec92f393ab5db23de6af785fbd";
      try {
        const axiosInstance = axios.create({
          baseURL: apiURL,
          headers: { Nonce: this.nonce },
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
        });
        return axiosInstance;
      } catch (err) {
        console.error("woocommerce connection err", err);
      }
    },
  },
  data: () => {
    return {
      items: [],
      data: {},
      nonce: "",
      value: 5,
      savedValue: null,
      disabled: true,
      loading: true,
    };
  },
};
</script>

<style scoped>
.cart-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  padding-inline: 30px;
  background-color: #f3f3f3;
  margin-bottom: 5px;
}

.empty-crt {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: large;
  padding-inline: 20px;
  font-family: "Font Awesome 5 Pro" !important;
  font-size: 24px;
}
.price-left {
  background-color: #f3f3f3;
  flex-direction: column;
  width: 50%;
  align-self: center;
  text-align: center;
  display: flex;
  padding: 5px;
  align-items: center;
}

.loading-ctr {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cart-item-txt {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.x {
  margin: 0px;
  font-family: "Font Awesome 5 Pro" !important;
  font-size: 20px;
  cursor: pointer;
  color: red;
}

.cart-right-txt {
  margin: 0;
  font-family: "Font Awesome 5 Pro" !important;
  margin-bottom: 0;
  font-size: 20px;
  display: flex;
  align-self: center;
}
.price-container {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 800px) {
  .price-container {
    display: flex;
    flex-direction: column;
  }

  .price-right {
    width: 100%;
    align-self: center;
    display: flex;
    font-family: "Font Awesome 5 Pro";
    text-align: center;
    align-items: center;
    justify-content: center;

    margin-top: 5px;
  }
  .price-left {
    width: 100%;
  }
}
p {
  margin: 0;
  margin-block: 0;
}
.cart-right {
  display: flex;
  gap: 15px;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
}

.price-right {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: center;
}
.price-right-txt {
  font-family: "Font Awesome 5 Pro" !important;
  font-size: 24px;
  text-transform: capitalize;
  font-weight: 600;
}

.input-ctr {
  display: flex;
  align-items: center;
}
</style>
