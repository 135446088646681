<template>
  <b-container id="product-checkout-modal" fluid class="p-0 ctr">
    <b-row class="m-0">
      <b-col class="p-0">
        <FormSteps
          :steps="$store.state.components.checkout.steps"
          title="Store Checkout"
          @back="back"
        ></FormSteps>
      </b-col>
    </b-row>
    <div
      class="appt-content d-flex flex-row"
      :class="{ bk_f3: cur_stage === 'appt_confirm' }"
    >
      <div
        class="select-area pd-30 scroll-area"
        :class="{ appt_confirm: cur_stage === 'appt_confirm' }"
        v-if="!appointmentComplete"
      >
        <div class="appt-sub-comp">
          <div class="validation-warning" v-if="hasErrors">
            <b-alert show dismissible variant="warning">
              {{ displayErrors }}
            </b-alert>
          </div>
          <appt-store
            v-else-if="cur_stage === 'checkout_store'"
            title="1. Select a Store"
            @next="next"
            @hideModal="hideModal"
          ></appt-store>
          <Cart
            v-else-if="cur_stage === 'checkout_cart'"
            title="2. Review Cart"
            @next="next"
            @back="back"
          ></Cart>
          <appt-contact
            v-else-if="cur_stage === 'checkout_contact'"
            title="3. Contact Info"
            @next="next"
            @back="back"
          ></appt-contact>
          <FormReview v-else formType="checkout" @back="back"></FormReview>
        </div>
      </div>
      <FormSuccess
        v-if="appointmentComplete"
        @closeModal="hideModal"
      ></FormSuccess>
      <!-- Start of Desktop Right Hand Review Area -->
      <div
        class="appt-details pd-30 scroll-area"
        v-if="cur_stage !== 'appt_confirm' && cur_stage !== 'appt_book'"
      >
        <div class="appt-cap1">Product Quote Request</div>
        <div class="appt-confirm-stages">
          <FormStoreReview @goToEdit="gotoEdit" :number="1"></FormStoreReview>
          <FormServiceReview
            @goToEdit="gotoEdit"
            :number="2"
          ></FormServiceReview>
          <FormContactReview
            @goToEdit="gotoEdit"
            :number="3"
          ></FormContactReview>
          <div class="confirm-stage">
            <div class="d-title">
              <span class="lbl-cap8 text-bold">4. Confirm Quote</span>
            </div>
          </div>
        </div>
      </div>
      <!-- End of Desktop Right Hand Review Area -->
    </div>
    <div class="appt-footer">
      Copyright &copy; 2022 Tint World&reg; Franchise. All rights reserved.
    </div>
  </b-container>
</template>

<script>
import FormSteps from "./../partials/FormSteps";
import FormStoreReview from "./../partials/FormStoreReview";
import FormServiceReview from "./../checkout/FormCartReview";
import FormContactReview from "./../partials/FormContactReview";
import FormSuccess from "./../partials/FormSuccess";
import FormReview from "../quote/FormReview.vue";
//import FormReview from "./../partials/FormReview";
//import ApptStore from "./ApptStore";
import ApptStore from "./../partials/FormStore";

import ApptContact from "./../partials/FormContact";
//import ApptConfirm from "./ApptConfirm"
import Cart from "./Cart.vue";

export default {
  name: "Checkout",
  components: {
    ApptStore,
    ApptContact,
    FormSteps,
    FormStoreReview,
    FormServiceReview,
    FormContactReview,
    FormSuccess,
    Cart,

    FormReview,
  },
  data: () => {
    return {
      hasErrors: false,
      displayErrors: "",
      confirmHeading: null,
      confirmBody: null,
      confirmPhone: null,
      confirmDetails: null,
    };
  },
  created() {
    this.$store.commit("userSelectedAppointmentDate", null);
    this.$store.commit("userSelectedAppointmentTime", null);
    if (this.storeSelected) {
      this.$store.dispatch(
        "getAvailability",
        this.storeSelected["location-id"]
      );
    }
    //@TODO - We can likely clear out old statuses simply from here / offering a graceful restart
  },
  mounted() {
    this.$gtm.trackEvent({
      event: "begin_checkout_appointment",
      category: "Lead",
      action: "Started Appointment",
      noninteraction: false,
    });
  },
  computed: {
    formStore() {
      return this.$store.state.user.services;
    },
    storeSelected() {
      return this.$store.getters.formStore;
    },
    formComplete() {
      return this.$store.getters.appointmentFormComplete;
    },
    appointmentComplete() {
      return this.$store.getters.appointmentProcessedSuccessfully;
    },
    user() {
      return this.$store.state.user;
    },
    appointment_modal() {
      return this.$store.state.appointment_modal;
    },
    cur_stage() {
      return this.$store.getters.currentCheckoutState.name;
    },
    appointment() {
      return this.$store.state.appointment;
    },
  },
  destroyed() {
    if (this.appointmentComplete) {
      this.$store.commit("resetUserForm", { type: "appointment" });
    }
  },
  methods: {
    gotoLink(url) {
      location.href = url;
      this.hideModal();
    },
    hideModal() {
      this.$root.$emit("bv::hide::modal", "tint-world-modal");
      this.$store.commit("CloseModal", "appointment");
    },
    toggleModal() {
      this.$root.$emit("bv::toggle::modal", "tint-world-modal");
      this.$store.commit("ToggleModal", "appointment");
    },
    openModal() {
      this.$root.$emit("bv::toggle::modal", "tint-world-modal");
      this.$store.commit("OpenModal", "appointment");
    },
    back() {
      var form = { type: "checkout", change: "reduce" };
      this.$store.commit("FormStepBackCheckout", form);
    },
    next() {
      var form = { type: "checkout", change: "increment" };
      this.$store.commit("FormStepBackCheckout", form);
      if (
        this.storeSelected &&
        this.cur_stage == "appt_service" &&
        this.$store.state.components.appointment.availability.length == 0
      ) {
        this.$store.dispatch(
          "getAvailability",
          this.storeSelected["location-id"]
        );
      }
    },
    gotoEdit(int) {
      var form = { type: "checkout", change: "go_to", go_to: int };
      this.$store.commit("FormStepBackCheckout", form);
    },
  },
};
</script>
<style scoped>
.product-checkout-modal {
  height: 100vh;
}
.ctr {
  height: 100vh;
  overflow: scroll;
}
</style>
