<template>
  <div class="appt-book">
    <div class="book-title"><p v-html="response.responseHeadline"></p></div>
    <div class="book-description">
      <p class="appt-text-subhead">
        <span v-html="response.responseMessage"></span>
      </p>
      <p class="appt-text-details">
        <span v-html="response.responseDetails"></span>
      </p>
      <p class="appointment-phone-confirmation">
        <span class="call-now-cta">Call Now: </span>
        <a :href="'tel:' + storePhone" class="appt-store-phone">{{
          storePhone
        }}</a>
      </p>
    </div>
    <div class="book-btn" @click="exitModal">BROWSE OUR SITE</div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
  computed: {
    response() {
      return this.$store.state.form_response;
    },
    store() {
      return this.$store.state.user.store;
    },
    storePhone() {
      return this.$store.getters.getUserStorePhone;
    },
    appt_modal() {
      return this.$store.state.components.appointment;
    },
    quote_modal() {
      return this.$store.state.components.quote;
    },
  },
  methods: {
    gotoEdit: function (index) {
      this.$emit("goToEdit", index);
    },
    exitModal() {
      this.$store.commit("resetUserForm", { type: "appointment" });
      this.$emit("closeModal");
    },
  },
};
</script>
